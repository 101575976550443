/* eslint-disable no-param-reassign */

/**
 * Common utility between front & back
 */

const moment = require('moment-timezone');

moment.tz.setDefault('Europe/Paris');

const formatDate = (date, now) => {
    if (!date) return ' ';

    // Try to use provided "now" if any, to use server-side side
    if (now) {
        now = moment(now);
    } else {
        now = moment();
    }

    // console.log('date1', date);
    date = moment(date);
    const diff = now.diff(date, 'minutes');
    // console.log('date2', date);
    // console.log('diff', diff);

    // Second case of this if should very rarely happen in production
    if (diff === 0 || diff > 0) {
        return 'Immin.';
    }

    if (diff <= -30) {
        return date.format('HH[h]mm');
    }

    return `${Math.abs(diff)} min`;
};

// Suposedly not used?
const formatTime = (time) => {
    const date = moment(time, 'HH:mm:ss');
    return formatDate(date);
};

module.exports = { formatDate, formatTime };
