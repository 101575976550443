/* eslint-disable no-param-reassign */
const { default: axios } = require('axios');
const moment = require('moment-timezone');
const { createApp } = require('vue/dist/vue.esm-bundler.js');
const { formatDate } = require('../../utility/common');

moment.tz.setDefault('Europe/Paris');

createApp({
    delimiters: ['((', '))'],
    data() {
        return {
            currentTs: 0,
            currentHour: null,
            currentLang: 'fr',
            words: {
                nextBus1: {
                    fr: 'Prochains passeurs',
                    en: 'Next shuttles',
                },
                nextBus2: {
                    fr: 'Prochains bus',
                    en: 'Next buses',
                },
                transportLine: {
                    fr: 'Ligne',
                    en: 'Line',
                },
                destination: {
                    fr: 'Destination',
                    en: 'Destination',
                },
                informations: {
                    fr: 'Information',
                    en: 'Information',
                },
                unavailable: {
                    fr: 'Départs indisponibles',
                    en: 'Data unavailable',
                },
            },
            departures: [],
            frequency: null,
            dayStart: null,
            dayEnd: null,
            isBeforeService: null,
            isAfterService: null,
            abnormalState: false,
            degradedMode: false,
            // `forceDisplay` allows to override to false the two others
            forceDisplay:
                window.location.search &&
                window.location.search.indexOf('force') > -1,
            message: {},
        };
    },
    mounted() {
        // Sync then, then execute main action (which needs time) then plan it
        this.syncTime().then(this.main);
        setInterval(this.main, 15 * 1000);

        // Change language every x seconds
        setInterval(() => {
            this.currentLang = this.currentLang === 'fr' ? 'en' : 'fr';
        }, 10000);

        // Sync & display time
        setInterval(this.syncTime, 10 * 60 * 1000);
        setInterval(this.setTime, 1 * 1000);
    },
    methods: {
        main() {
            const promises = [];

            // Push to classical file everytime
            promises.push(axios.get(`/generated/${window.preloaded.fileName}`));

            // And sometimes, load a complementary file too
            // #codetag:complement_stop
            if (window.preloaded.fileNameComplement) {
                promises.push(
                    axios.get(
                        `/generated/${window.preloaded.fileNameComplement}`
                    )
                );
            }

            Promise.all(promises)
                .then((responses) => {
                    // Merge complementary departures with base departures
                    // #codetag:complement_stop
                    const response = responses[0];
                    if (responses.length > 1) {
                        response.data.departures =
                            response.data.departures.concat(
                                responses[1].data.departures
                            );

                        // Sort again (merging leads to wrong order)
                        response.data.departures.sort((a, b) => {
                            return moment(a.departure).diff(
                                moment(b.departure),
                                'seconds'
                            );
                        });
                    }

                    // TODO: Comment on fait si, pour le moment, pas de currentTs ? (au début notamment)

                    const now = moment(this.currentTs);

                    if (response.data.departures) {
                        this.departures = response.data.departures
                            .filter((d) => !!d)
                            // Client-side past items filtering
                            .filter((d) => {
                                return now.isBefore(
                                    moment(d.departure),
                                    'second'
                                );
                            })
                            // Limit to 2 items on display, 3 when dual-stops display
                            .slice(0, responses.length > 1 ? 3 : 2);
                    } else {
                        this.departures = [];
                    }

                    // Send a clone to the method to ensure nothing is linked
                    const departuresCopy = JSON.parse(
                        JSON.stringify(this.departures)
                    );

                    this.departures.map((departure) => {
                        // Format time client-side
                        departure.backTime = departure.time;
                        departure.time = formatDate(departure.departure, now);

                        return departure;
                    });

                    this.frequency = response.data.frequency;
                    this.margin = response.data.margin;

                    this.message = response.data.message;
                    this.dayStart = response.data.dayStart;
                    this.dayEnd = response.data.dayEnd;
                    this.isBeforeService = response.data.isBeforeService;
                    this.isAfterService = response.data.isAfterService;

                    this.degradedMode = this.forceDisplay
                        ? false
                        : response.data.degradedMode;

                    // TODO: Only for L1
                    this.updateStateNormality(departuresCopy);
                })
                .catch((e) => {
                    console.log('Issue.', e);
                });
        },

        syncTime() {
            return axios
                .get('/visiteurs/time')
                .then((response) => {
                    this.currentTs = response.data.ts;
                })
                .catch(() => {
                    this.currentTs = 0;
                });
        },

        setTime() {
            if (!this.currentTs) {
                this.currentHour = '';
                return;
            }

            this.currentTs += 1000;
            this.currentHour = moment(this.currentTs).format('HH:mm');
        },

        // Used only in `updateStateNormality`
        getTimingInfo(time1, time2) {
            const expectedFrequency = parseFloat(this.frequency);
            const maxDelay = parseFloat(this.margin);
            const diff = moment(time2).diff(moment(time1), 'seconds');

            const output = {
                waitInSeconds: diff,
                waitInMinutes: diff / 60, // Just for debug
                expectedFrequency, // 1 bus every x minutes
                maxDelay, // in minutes
            };

            if (output.waitInMinutes < output.expectedFrequency) {
                output.result = 'in-advance';
            } else if (output.waitInMinutes === output.expectedFrequency) {
                output.result = 'on-time';
            } else {
                // waitInMinutes > expectedFrequency
                const delay = output.waitInMinutes - output.expectedFrequency;

                if (delay <= output.maxDelay) {
                    output.result = 'late';
                } else {
                    output.result = 'unacceptable';
                }
            }

            return output;
        },

        updateStateNormality(departures) {
            if (!departures || !departures.length) {
                // No departure found, let's say the state is abnormal
                this.abnormalState = true;
                return;
            }

            const results = [];

            // At least one departure to look at
            results.push(
                this.getTimingInfo(
                    moment(this.currentTs),
                    departures[0].departure
                )
            );

            if (departures.length > 1) {
                results.push(
                    this.getTimingInfo(
                        departures[0].departure,
                        departures[1].departure
                    )
                );
            }

            // Display for debug
            results.forEach((result) => {
                console.log(result);
            });
            console.log('---');

            if (this.forceDisplay) {
                // We can force data to be shown
                this.abnormalState = false;
                return;
            }

            this.abnormalState = results.some(
                (result) => result.result === 'unacceptable'
            );
        },
    },

    computed: {
        // Please keep in sync with back's `replaceAfterMidnight`
        // #codetag:after_midnight
        humanDayEnd() {
            if (!this.dayEnd) return this.dayEnd;

            return this.dayEnd
                .replace('24:', '00:')
                .replace('25:', '01:')
                .replace('26:', '02:')
                .replace('27:', '03:');
        },
    },
}).mount('#app');
